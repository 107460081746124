.mono
  font-family: Menlo, Monaco, Consolas, monospace,source-code-pro

.App
  .content
    display: flex
    flex-direction: column
    justify-content: center
    font-size: calc(10px + 2vmin)
    color: white
    padding-top: 2rem

    .bot
      display: flex
      flex-flow: row nowrap
      width: 80vw
      @extend .mono
      font-size: 1rem

      .shellButton
        display: flex
        flex-flow: column nowrap
        justify-content: center
        align-items: center
        border: none
        text-align: center
        width: 2rem
        border-radius: 0.5rem 0rem 0rem 0.5rem
        background: #222
        color: lime
      .image
        height: 5rem
      .id
        text-align: right
        // width: 30vw
        .heart
          position: relative
          &.alive
            animation: pulse alternate infinite 0.5s linear
        .name
          position: relative
          font-size: 0.6rem
          &.alive
            color: lime
          &.dead
            color: crimson
      .info
        word-break: break-all
        width: 50vw
        font-size: 1rem
        text-align: center
        padding-left: 2rem
        .lastSeen
          font-size: 0.8rem
          text-align: left
          color: crimson
        .hash
          opacity: 0.4
          border-radius: 0.2rem
          width: 6rem
          text-shadow: 0.0rem 0.0rem 0.2rem black
          font-size: 0.6rem
        .ips
          .ip
            display: flex
            flex-flow: row nowrap
            .iface
              width: 7rem
              text-align: right
              opacity: 0.4
              white-space: pre
              word-break: keep-all
            .addr
              width: 12rem
              text-align: left
              padding-left: 1rem
              .mac
                width: 10rem
                font-size: 0.7rem
                // text-align: right
                opacity: 0.4
                text-transform: uppercase

  .shell
    height: 100vh
    width: 100vw
    top: 0
    left: 0
    background: black
    position: fixed
    display: flex
    flex-flow: column nowrap
    .title
      background: black
      color: lime
      display: flex
      flex-flow: row nowrap
      font-size: 1.1rem
      font-weight: bold
      button
        font-weight: bold
        font-size: 2rem
      .name
        flex-grow: 1

    .terminal
      display: flex
      flex-flow: column nowrap
      text-align: left
      padding-left: 1rem
      color: gray
      flex-grow: 1
      overflow: auto
      font-family: Monaco, Menlo, monospace
      white-space: pre
      .command
        color: yellow
    .prompt
      display: flex
      flex-flow: row nowrap
      font-size: 1.5rem
      background: #222
      color: yellow
      .pound
        font-family: Monaco, Menlo, monospace
        width: 2rem
      input
        background: #222
        border: none
        flex-grow: 1

        color: yellow
        font-size: 1.5rem
        font-family: Monaco, Menlo, monospace
        outline: none

  .App-logo
    height: 40vmin
    pointer-events: none

  @media (prefers-reduced-motion: no-preference)
    .App-logo
      animation: App-logo-spin infinite 20s linear

  .App-link
    color: #61dafb

  @keyframes pulse
    from
      opacity: 0.1
    to
      opacity: 1.0
